<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="editEmployee"
              v-if="employee && onProgress === false">

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Роль <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('role_id') }">
                <multiselect :allow-empty="true"
                             :options="roles"
                             :searchable="false"
                             @input="() => {
                               form.role_id = role ? role.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Выберите должность"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="role">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="role_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Отдел
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('department_id') }">
                <multiselect :allow-empty="true"
                             :options="employee_departments"
                             :searchable="false"
                             @input="() => {
                               form.department_id = employee_department ? employee_department.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Выберите отдел"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="employee_department">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="department_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="name">
                Имя <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('name') }">
                <input class="form-control"
                       id="name"
                       name="name"
                       required
                       type="text"
                       v-model="form.name">
                <HasError :form="form" field="name"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="email">
                Email <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('email') }">
                <input class="form-control"
                       id="email"
                       name="email"
                       required
                       type="email"
                       v-model="form.email">
                <HasError :form="form" field="email"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Телефон <span class="req"/>
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('phone') }">
                <vue-tel-input id="phone"
                               name="phone"
                               v-bind='phone_config'
                               v-model="form.phone">
                </vue-tel-input>
                <HasError :form="form" field="phone"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Телефон #2
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('phone_two') }">
                <vue-tel-input id="phone_two"
                               name="phone_two"
                               v-bind='phone_config'
                               v-model="form.phone_two">
                </vue-tel-input>
                <HasError :form="form" field="phone_two"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Филиал
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('branch_id') }">
                <multiselect :allow-empty="true"
                             :options="branches"
                             :searchable="false"
                             :show-labels="true"
                             @input="() => {
                               form.branch_id = branch ? branch.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Выберите филиал"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="branch">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.title }}, {{ props.option.address }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                  </template>
                </multiselect>
                <HasError :form="form" field="branch_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label">
                Город
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('city_id') }">
                <multiselect :allow-empty="true"
                             :options="cities"
                             :searchable="false"
                             @input="() => {
                               form.city_id = city ? city.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Выберите город"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             track-by="id"
                             v-model="city">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="city_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="password">
                Пароль
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('password') }">
                <input class="form-control"
                       id="set_password"
                       name="set_password"
                       type="password"
                       v-model="form.password">
                <small>Для смены пароля введите новый пароль</small>
                <HasError :form="form" field="password"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="password_confirm">
                Подтверждение
              </label>
              <div class="col-md-10"
                   v-bind:class="{ 'has-error': form.errors.has('password_confirm') }">
                <input class="form-control"
                       id="set_password_confirm"
                       name="set_password_confirm"
                       placeholder="Введите пароль повторно"
                       type="password"
                       v-model="form.password_confirm">
                <HasError :form="form" field="password_confirm"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col-md-2 control-label" for="file">
                Фото <span class="req"/>
              </label>
              <div class="col-md-4"
                   v-bind:class="{ 'has-error': form.errors.has('file') }">
                <input class="form-control"
                       id="file"
                       name="file"
                       type="file"
                       @change="setFile">
                <HasError :form="form" field="file"/>
              </div>
              <div class="col-md-6">
                <img v-if="employee_photo" class="img-fluid" :src="employee_photo" alt="" style="max-height: 300px;">
              </div>
            </div>
          </div>

          <br/>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  type="submit"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-warning pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-edit"></i>
          </Button>
        </form>
        <loading-processing v-else/>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import phone_config from "./../../assets/data/vue-tel-input-config.json";
import RolesService from "@/services/RolesService";
import EmployeeDepartmentsService from "@/services/EmployeeDepartmentsService";
import BranchService from "@/services/BranchService";
import CitiesService from "@/services/CitiesService";

Form.axios = API.apiClient;

export default {
  name: 'edit',

  components: {
    VueTelInput,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: [
    'employeeId',
  ],

  data: () => ({
    page_title: 'Изменить сотрудника',

    form: null,

    employee: null,
    employee_photo: null,

    roles: [],
    role: null,
    employee_departments: [],
    employee_department: null,
    branches: [],
    branch: null,
    cities: [],
    city: null,

    phone_config: phone_config,

    onProgress: true,
  }),

  async created() {
    try {
      const employee_response = await API.apiClient.get('/employee/' + this.employeeId, {
          params: {
            relations: [
              'role',
              'department',
              'branch',
              'city',
            ],
          }
        });

      this.employee = employee_response.data;
      this.employee.phone = '+' + this.employee.phone.toString();
      if (this.employee.phone_two) {
        this.employee.phone_two = '+' + this.employee.phone_two.toString();
      }
      this.employee_photo = this.employee.photo_url;

      this.roles = await RolesService.getItems();
      this.employee_departments = await EmployeeDepartmentsService.getItems();
      this.branches = await BranchService.getItems();
      this.cities = await CitiesService.getItems();

      this.fillEmployeeForm();
    } catch (error) {
      errorHandler(error);
    }
    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.role_id && this.form.name && this.form.email && this.form.phone &&
          ((this.form.password && this.form.password.length) ? this.form.password === this.form.password_confirm : true);
    },
  },

  methods: {
    fillEmployeeForm() {
      try {
        this.role = this.employee.role;
        this.employee_department = this.employee.department ? this.employee.department : null;
        this.branch = this.employee.branch ? this.employee.branch : null;
        this.city = this.employee.city ? this.employee.city : null;

        this.form = new Form({
          role_id: this.employee.role.id,
          department_id: this.employee.department ? this.employee.department.id : null,
          name: this.employee.name,
          email: this.employee.email,
          phone: this.employee.phone,
          phone_two: this.employee.phone_two,
          branch_id: this.employee.branch ? this.employee.branch.id : null,
          city_id: this.employee.city ? this.employee.city.id : null,
          password: null,
          password_confirm: null,
        });
      } catch (error) {
        errorHandler(error);
      }
    },

    async setFile(event) {
      try {
        this.form.file = event.target.files[0];
        this.employee_photo = URL.createObjectURL(this.form.file);
      } catch (error) {
        errorHandler(error);
      }
    },

    async editEmployee() {
      await this.form.post('/employees/edit/' + this.employeeId).then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/employees'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped>

</style>
